








































import { defineComponent, useContext } from '@nuxtjs/composition-api';
import Icon from '@/almarwan/components/Icon.vue';

export default defineComponent({
  name: 'Faq',
  components: {
    Icon,
  },
  data() {
    return {
      isActive: null
    }
  },
  mounted() {
    this.isActive=0
  },
  methods: {
    toggleAccordion(index) {
      if(this.isActive !== index) {
        this.isActive = index
      }
      else {
        this.isActive = null
      }
    }
  },
  setup() {
    const { app: { i18n } } = useContext();
    const faqList = [
      {
        title: i18n.t('What heavy equipment services does Al Marwan offer?'),
        content: i18n.t("Al Marwan is a leading provider of heavy construction equipment in the UAE, Saudi Arabia, and Oman. We rent out well-maintained, young machinery to support large scale projects in the region. We’re also the authorized distributor of Kobelco, Powerscreen, Dynapac, and ABI equipment, and a trusted source for the resale of pre-owned CAT, Volvo, Komatsu, among other global brands of equipment."),
      },
      {
        title: i18n.t('What types of heavy equipment does Al Marwan supply?'),
        content: i18n.t('Al Marwan offers a wide selection of like-new & used machinery that is well-maintained and is proven to work efficiently for clients from different industries around the world from renowned brands including Caterpillar, Volvo, Komatsu, JCB, Hitachi, and more, spanning excavators, loaders, dozers, cranes, trucks, compactors, drills, and crushers & screeners.'),
      },
      {
        title: i18n.t('What industries does Al Marwan service?'),
        content: i18n.t("We offer a wide range of heavy machinery for the construction, oil & gas, landscaping, municipalities, general contracting, mining, and quarry & aggregate industries."),
      },
      {
        title: i18n.t('Do you sell heavy equipment spare parts?'),
        content: i18n.t('We sell OEM heavy machinery spare parts for any machine, spanning various brands such as Kobelco, ABI, Dynapac, Komatsu, and many others. From engine components to rings and bolts, we guarantee that our customers will get the parts they need in a timely manner.'),
      }
    ]

    return {
      faqList
    }
  }
})
